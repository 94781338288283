var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      style: _vm.backCss,
      attrs: {
        placement: "right",
        width: _vm.isFullScreen ? _vm.screenWidth : _vm.drawerWidth,
        visible: _vm.show,
        maskClosable: _vm.isMaskClosable || !_vm.isPreview,
        keyboard: !_vm.isPreview,
        wrapClassName: "drawer-layout",
        height: "100%",
        destroyOnClose: _vm.isPreview || _vm.destroyOnClose,
      },
      on: { close: _vm.closed },
    },
    [
      _c(
        "div",
        { staticClass: "pb-14", on: { click: _vm.drawerClick } },
        [
          _vm._t("other-region"),
          _vm.noEditBtn
            ? _c("div", [
                _vm.isForm
                  ? _c(
                      "div",
                      { staticClass: "detail-wrap" },
                      [_vm._t("default"), _vm._t("form")],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "form-wrap" },
                      [_vm._t("form")],
                      2
                    ),
              ])
            : _c("div", [
                !_vm.showForm
                  ? _c(
                      "div",
                      { staticClass: "detail-wrap" },
                      [
                        !_vm.isEdit && !_vm.isPreview && !_vm.hideEditBtn
                          ? _c(
                              "div",
                              { staticClass: "flex jc-end mb-4 eidtor-btn" },
                              [
                                _vm._t("other-buttons"),
                                _c(
                                  "a-button",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: _vm.permission,
                                        expression: "permission",
                                      },
                                    ],
                                    attrs: { type: "primary", icon: "edit" },
                                    on: { click: _vm.editForm },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._t("default"),
                      ],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "form-wrap" },
                      [_vm._t("form")],
                      2
                    ),
              ]),
          _vm.showForm &&
          !_vm.isUser &&
          ((_vm.isChapter && !_vm.isFullScreen) || !_vm.isChapter)
            ? _c(
                "div",
                {
                  staticClass:
                    "bg-white absolute inset-x-0 bottom-0 h-16 flex jc-end items-center px-4 border-top",
                  staticStyle: { "z-index": "99" },
                },
                [
                  _c(
                    "a-button",
                    { staticClass: "mr-4", on: { click: _vm.closed } },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        type: !_vm.isChapter ? "primary" : "",
                        loading: _vm.btnLoading,
                      },
                      on: { click: _vm.saveForm },
                    },
                    [_vm._v(_vm._s(_vm.isHelp ? "发送" : "保存") + "\n      ")]
                  ),
                  _vm.isChapter
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.showCalendar },
                        },
                        [_vm._v(" 预览 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm.showForm && _vm.isUser
            ? _vm._t("footer")
            : _vm._e(),
          _vm.isPreview
            ? _c(
                "div",
                {
                  staticClass:
                    "bg-white absolute inset-x-0 bottom-0 h-16 flex jc-end items-center px-4 border-top",
                  staticStyle: { "z-index": "9" },
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.confirmBtnDisable,
                      },
                      on: { click: _vm.onConfirm },
                    },
                    [_vm._v(" 确认 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "flex", attrs: { slot: "title" }, slot: "title" },
        [
          _vm._t("title-icon"),
          _c("div", { staticClass: "text-lg text-medium flex-1" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          !_vm.isChapter
            ? _c(
                "a",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function ($event) {
                      _vm.isFullScreen = !_vm.isFullScreen
                    },
                  },
                },
                [
                  _c("a-icon", {
                    staticClass: "text-xl tc-gray-600",
                    attrs: {
                      type: !_vm.isFullScreen
                        ? "fullscreen"
                        : "fullscreen-exit",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isPreview
            ? _c(
                "a",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closed", false)
                    },
                  },
                },
                [
                  _c("a-icon", {
                    staticClass: "text-xl tc-gray-600",
                    attrs: { type: "close" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }