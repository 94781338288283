<template>
  <a-drawer
    :placement="'right'"
    :style="backCss"
    :width="isFullScreen ? screenWidth : drawerWidth"
    :visible="show"
    :maskClosable="isMaskClosable || !isPreview"
    :keyboard="!isPreview"
    wrapClassName="drawer-layout"
    @close="closed"
    height="100%"
    :destroyOnClose="isPreview || destroyOnClose"
  >
    <div class="pb-14" @click="drawerClick">
      <slot name="other-region"></slot>
      <div v-if="noEditBtn">
        <div class="detail-wrap" v-if="isForm">
          <slot></slot>
          <slot name="form"></slot>
        </div>
        <div class="form-wrap" v-else>
          <slot name="form"></slot>
        </div>
      </div>

      <div v-else>
        <!-- <div>{{ { hideEditBtn: hideEditBtn } }}</div> -->
        <div class="detail-wrap" v-if="!showForm">
          <div class="flex jc-end mb-4 eidtor-btn" v-if="!isEdit && !isPreview && !hideEditBtn">
            <slot name="other-buttons"></slot>
            <a-button type="primary" v-has="permission" icon="edit" @click="editForm">编辑</a-button>
          </div>
          <slot></slot>
        </div>
        <div class="form-wrap" v-else>
          <slot name="form"></slot>
        </div>
      </div>
      <div
        class="bg-white absolute inset-x-0 bottom-0 h-16 flex jc-end items-center px-4 border-top"
        style="z-index: 99"
        v-if="showForm && !isUser && ((isChapter && !isFullScreen) || !isChapter)"
      >
        <a-button class="mr-4" @click="closed"> 取消 </a-button>
        <a-button class="mr-4" :type="!isChapter ? 'primary' : ''" :loading="btnLoading" @click="saveForm"
          >{{ isHelp ? '发送' : '保存' }}
        </a-button>
        <a-button v-if="isChapter" type="primary" @click="showCalendar"> 预览 </a-button>
      </div>
      <slot name="footer" v-else-if="showForm && isUser"></slot>
      <div
        class="bg-white absolute inset-x-0 bottom-0 h-16 flex jc-end items-center px-4 border-top"
        style="z-index: 9"
        v-if="isPreview"
      >
        <a-button type="primary" :disabled="confirmBtnDisable" @click="onConfirm"> 确认 </a-button>
      </div>
    </div>
    <div class="flex" slot="title">
      <slot name="title-icon"></slot>
      <div class="text-lg text-medium flex-1">{{ title }}</div>
      <a v-if="!isChapter" @click="isFullScreen = !isFullScreen" class="ml-4">
        <a-icon :type="!isFullScreen ? 'fullscreen' : 'fullscreen-exit'" class="text-xl tc-gray-600" />
      </a>
      <a v-if="!isPreview" @click="$emit('closed', false)" class="ml-4">
        <a-icon type="close" class="text-xl tc-gray-600" />
      </a>
    </div>
  </a-drawer>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    title: String,
    show: {
      type: Boolean,
      default: false
    },
    btnLoading: {
      type: Boolean,
      default: false
    },
    isForm: {
      type: Boolean,
      default: false
    },
    drawerWidth: {
      type: Number,
      default: 700
    },
    isUser: {
      type: Boolean,
      default: false
    },
    noEditBtn: {
      type: Boolean,
      default: false
    },
    permission: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: () => false
    },
    tasks: {
      type: Array,
      default: () => []
    },
    isChapter: {
      type: Boolean,
      default: () => false
    },
    destroyOnClose: {
      type: Boolean,
      default: () => false
    },
    isMaskClosable: {
      type: Boolean,
      default: () => false
    },
    isHelp: {
      type: Boolean,
      default: () => false
    },
    hideEditBtn: {
      type: Boolean,
      default: () => false
    },
    confirmBtnDisable: {
      type: Boolean,
      default: () => true
    }
  },
  watch: {
    show(n) {
      this.$emit('show')
      if (n) {
        if (this.isForm) {
          this.showForm = true
        }
      } else {
        this.isEdit = false
        this.showForm = false
      }
    },
    isForm(n) {
      console.log('新建表单', n)
    }
  },
  data() {
    return {
      isFullScreen: false,
      screenWidth: 0,
      isEdit: false,
      showForm: false,
      loading: false
    }
  },
  computed: {
    backCss() {
      let paddingHeight=this.isChapter?'0':'4rem'
      return {
        '--padding-height': paddingHeight
      }
    }
  },
  methods: {
    ...mapMutations(['closeAllEditItemSelects']),
    editForm() {
      this.isEdit = true
      this.showForm = true
      this.$emit('edit')
    },
    showCalendar() {
      this.$emit('showScreen')
    },
    closed() {
      this.$emit('closed', false)
    },
    saveForm() {
      this.$emit('save')
    },
    showFullScreen(full) {
      this.isFullScreen = full
    },
    onConfirm() {
      console.log('drawer-layout onConfirm')
      this.$emit('onConfirm')
      this.$emit('closed', false)
    },
    drawerClick(e) {
      /* console.log('drawerClick', { e }); */
      this.closeAllEditItemSelects?.()
      this.$emit('drawerClick', e)
    }
  },
  created() {},
  mounted() {
    this.screenWidth = document.documentElement.clientWidth
  }
}
</script>

<style lang="less">
.drawer-layout {
  .ant-drawer-content {
    overflow: hidden;
  }

  .ant-drawer-wrapper-body {
    overflow: hidden;
  }

  .ant-drawer-body {
    overflow: auto;
    height: 100%;
    padding-bottom: var(--padding-height);
  }

  .form-wrap {
    margin-bottom: var(--padding-height);
  }

  .ant-drawer-close {
    display: none;
  }
}
</style>
